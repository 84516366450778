import React from 'react';
import { NavLink } from 'react-router-dom';

import '../css/footer.css'; 

import WebpImage from '../components/WebpImage';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-logo-container">
                <NavLink to="/">
                        <WebpImage 
                            webpSrc={require("../assets/shared/crestview-logo.webp")}
                            imgSrc={require("../assets/shared/crestview-logo.png")}
                            altText="crestview senior living"
                        />
                </NavLink>
            </div>
            <div className="footer-link-section-container">
                <div className="footer-link-section">
                    <hr className="footer-divider"></hr>
                    <hr className="footer-divider"></hr>
                    <NavLink className="footer-link" to="/assisted-living">
                        Assisted Living
                    </NavLink>
                    <NavLink className="footer-link" to="/memory-care">
                        Memory Care
                    </NavLink>
                    <NavLink className="footer-link" to="/respite-care">
                        Respite Care
                    </NavLink>
                    <NavLink className="footer-link" to="/independent-cottages">
                        Independent Cottages
                    </NavLink>
                    <NavLink className="footer-link" to="/gallery">
                        Gallery
                    </NavLink>
                </div>
                <div className="footer-link-section">
                    <hr className="footer-divider"></hr>
                    <hr className="footer-divider"></hr>
                    <NavLink className="footer-link" to="/contact">
                        Live Here
                    </NavLink>
                    <NavLink className="footer-link" to="/careers">
                        Work Here
                    </NavLink>
                    <NavLink className="footer-link" to="/floor-plans">
                        Explore
                    </NavLink>
                    <NavLink className="footer-link" to="/covid-19">
                        Covid-19 Guidelines
                    </NavLink>
                </div>
            </div>
            <div className="footer-alt-info-container">
                <a className="footer-link" href="https://goo.gl/maps/y8XibyZJJfytHjeG6">
                    <div className="footer-text">3310 W. Main St</div>
                </a>
                <div className="footer-text-barrier"> | </div>
                <a className="footer-link" href="https://goo.gl/maps/y8XibyZJJfytHjeG6">
                    <div className="footer-text">Cabot, AR 72023</div>
                </a>
                <div className="footer-text-barrier"> | </div>
                <a className="footer-link" href="tel:+15014226290
">
                    <div className="footer-text">(501)422-6290</div>
                </a>
            </div>
            <div className="footer-awards-container">
                <a className="best-in-senior-living-award-container" target="_blank" href="https://assistedlivingmagazine.com/featured/best-assisted-livign-in-little-rock-ar/">
                    <WebpImage 
                        webpSrc={require("../assets/shared/best-in-senior-living-award.webp")}
                        imgSrc={require("../assets/shared/best-in-senior-living-award.png")}
                        altText="crestview senior living"
                        isClickable
                        />
                </a>
                <div className="best-meals-and-dining-award-container">
                    <WebpImage 
                        webpSrc={require("../assets/shared/best-meals-and-dining.webp")}
                        imgSrc={require("../assets/shared/best-meals-and-dining.jpg")}
                        altText="crestview senior living"
                        />
                </div>
            </div>
            <div className="connect-with-us-text">Connect with us</div>
            <div className="social-media-container">
                <a className="facebook-icon" target="_blank" alt="crestview senior living facebook page" href="https://facebook.com/crestviewsl">
                    <FacebookIcon style={{ fontSize: 40 }}/>
                </a>
                <a className="instagram-icon" target="_blank" alt="crestview senior living instagram page" href="https://instagram.com/crestviewsl">
                    <InstagramIcon style={{ fontSize: 40 }}/>
                </a>
                <a className="linkedin-icon" target="_blank" alt="crestview senior living linkedIn page" href="https://www.linkedin.com/company/crestview-senior-living-cabot-arkansas">
                    <LinkedInIcon style={{ fontSize: 40 }}/>
                </a>
            </div>
            <div className="eqo-handicap-container">
                <div className="eqo-handicap-picture">
                    <WebpImage 
                        webpSrc={require("../assets/shared/eqo.webp")}
                        imgSrc={require("../assets/shared/eqo.png")}
                        altText="crestview senior living"
                    />
                </div>
            </div>
        </footer>
    );
}
 
export default footer;