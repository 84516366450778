import React from 'react';
import '../css/sticky-header.css'; 
import WebpImage from './WebpImage';
import { NavLink } from 'react-router-dom';
import { Services } from '../helperJS/services';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import MapIcon from '@mui/icons-material/Map';
import HomeIcon from '@mui/icons-material/Home';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import CallIcon from '@mui/icons-material/Call';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StickyHeader = ({ isCovidAlertOpen, closeCovidAlertBanner }) => {

    return (
        <div className="sticky-header-container">
            <div className="sticky-header-inner-container" id="stickyHeader">
                <div className="cv-logo-container-header" id="gtco-logo">
                    <NavLink className="cv-logo-link" to="./">
                        <WebpImage 
                            webpSrc={require("../assets/shared/crestview-logo.webp")}
                            imgSrc={require("../assets/shared/crestview-logo.png")}
                            altText="crestview senior living"
                        />
                    </NavLink>
                </div>
                <div className="header-info-container">
                    <nav className="header-navigation">
                        <NavLink className="header-nav-link" to="/"> 
                            <div className="header-nav-link-icon">
                                <HomeIcon />
                            </div>
                            Home
                        </NavLink> 
                        <div className="header-nav-barrier"> | </div> 
                        <div className="dropdown">
                            <button className="dropbtn">
                                <div className="header-nav-link-icon">
                                    <RoomServiceIcon />
                                </div>
                                Lifestyle Options
                                <div className="dropdown-icon">
                                    <ArrowDropDownIcon />
                                </div>
                            </button>
                            <div className="dropdown-content">
                                {
                                    Services.map(service => 
                                        <NavLink to={service.link} key={service.heading}>{service.heading}</NavLink> 
                                    )
                                }
                            </div>
                        </div>
                        <div className="header-nav-barrier"> | </div>
                        <NavLink className="header-nav-link" to="/gallery">
                            <div className="header-nav-link-icon">
                                <ImageIcon />
                            </div>
                            Gallery
                        </NavLink>
                        <div className="header-nav-barrier"> | </div>
                        <NavLink className="header-nav-link" to="/floor-plans">
                            <div className="header-nav-link-icon">
                                <MapIcon />
                            </div>
                            Floor Plans
                        </NavLink>
                        <div className="header-nav-barrier"> | </div>
                        <NavLink className="header-nav-link" to="/dining">
                            <div className="header-nav-link-icon">
                                <LocalDiningIcon />
                            </div>
                            Dining
                        </NavLink>
                        <div className="header-nav-barrier"> | </div>
                        <NavLink className="header-nav-link" to="/contact">
                            <div className="header-nav-link-icon">
                                <CallIcon />
                            </div>
                            Contact
                        </NavLink>

                        {/* Hidden Links */}
                        <NavLink className="header-nav-link-hidden" to="/careers">
                            <div className="header-nav-link-icon" />
                            Careers
                        </NavLink> 
                    </nav>				
                </div>
            </div>
            {
                isCovidAlertOpen &&
                    <div className="covid-alert-container">
                        <NavLink className="sticky-header-alert-container" to="/covid-19">
                            Our Response to the Covid-19 Pandemic
                        </NavLink>
                        <CloseIcon 
                            className="close-button-covid-alert" 
                            style={{ fontSize: 40 }}
                            onClick={() => closeCovidAlertBanner()}
                        />    
                    </div>
            }
        </div>
    );
}
 
export default StickyHeader;