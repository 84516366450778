import React, { Component } from 'react';
import '../css/row-to-item.css';

import { ItemComponents } from '../helperJS/enums';

import Offer from './Offer';
import ServiceItem from './ServiceItem';
import GalleryImage from './GalleryImage';
import FloorPlanCard from './FloorPlanCard';

class RowToItems extends Component {
    constructor(props) {
        super(props)

        this.getItemComponent = this.getItemComponent.bind(this);
    }

    getItemComponent(itemComponentType, item, index) {
        switch(itemComponentType) {
            case ItemComponents.Service:
                return <ServiceItem item={item} key={index} index={index} />;
            case ItemComponents.FloorPlans:
                return <FloorPlanCard item={item} key={index} index={index} />;
            case ItemComponents.Offers:
                return <Offer item={item} key={index} index={index} />;
            case ItemComponents.Gallery:
                return <GalleryImage item={item} key={index} index={index} />;
            default: 
                return;
        }
    }

    render() {
        const {
            itemComponentType,
            rowToConvert
        } = this.props

        return (
            <div className="item-row-container">
                { 
                    rowToConvert.map( (item, index) =>
                        this.getItemComponent(itemComponentType, item, index)
                    )
                }
            </div>
        )
    }
}

export default RowToItems;