import React from 'react';

import '../css/banner-small.css'; 

import WebpImage from './WebpImage';

const SmallBanner = ({ imgSrc, webpSrc, altText }) => {
    return (
        <div className="banner-container animated fadeIn">
            <div className="picture-container">
                <WebpImage 
                    webpSrc={webpSrc}
                    imgSrc={imgSrc}
                    altText={altText}
                />
            </div>
            <div className="logo-container animated fadeIn delay-1s">
                <WebpImage 
                    webpSrc={require("../assets/shared/crestview-text-logo.webp")}
                    imgSrc={require("../assets/shared/crestview-text-logo.png")}
                    altText="Crestview Senior Living - Cabot, Arkansas"
                    isLogo={true}
                />
            </div>
        </div>
    );
}
 
export default SmallBanner;