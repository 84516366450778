import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTopOnPageLoad extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        if(window.location.pathname !== '/contact') {
          window.scrollTo(0, 0);
        }
      }
    }
  
    render() {
      return this.props.children || null;
    }
}
  
export default withRouter(ScrollToTopOnPageLoad)