import React from 'react';
import '../css/floor-plan-card.css'

import WebpImage from './WebpImage';

const FloorPlanCard = ({ item: { name, numOfBedrooms, numOfBathrooms, imgSrc, webpSrc, squareFootage }}) => {
    return (
        <div className="floor-plan-container">
            <div className="floor-plan-name animated fadeIn">{name}</div>
            <div className="floor-plan-property animated fadeIn">Bedrooms: {numOfBedrooms}</div>
            <div className="floor-plan-property  animated fadeIn"> Bathrooms: {numOfBathrooms}</div>
            <div className="floor-plan-picture animated fadeIn">
                <WebpImage
                    imgSrc={imgSrc}
                    webpSrc={webpSrc}
                    altText={name}
                    expandable={true}
                />
            </div>
            <div className="square-footage">{squareFootage}</div>
        </div>
    );
}
 
export default FloorPlanCard;