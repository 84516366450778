import PropTypes from "prop-types";
import React, { Component } from 'react';
import '../../css/form-section.css';

class FormSectionInputEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: 'none',
            isInFocus: false,
        }
        this.validateEmail = this.validateEmail.bind(this);
        this.updateOnFocus = this.updateOnFocus.bind(this);
    }

    validateEmail({ target: { value } }) {
        let errorToSet;
        let isInFocusToSet;
        var emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,6})?$/;
        if (value === '' || !emailRegex.test(value)) {
            errorToSet = 'Please provide a valid email address';
            isInFocusToSet = false;
        } else {
            errorToSet = 'none';
            isInFocusToSet = false;
        }

        this.setState({ 
            email: value,
            error: errorToSet,
            isInFocus: isInFocusToSet
        }, () => {
            this.props.getInput(this.state.email, this.props.id, this.state.error);
        })
    }

    updateOnFocus() {
        this.setState({ isInFocus: true });
    }

    render() {
        const {
            sectionName,
            placeHolder,
            id,
        } = this.props;

        const {
            error,
            isInFocus,
        } = this.state;

        let inputClass = (error !== 'none') ? "section-input error" : "section-input";
        let errorMessageClass = (error !== 'none' && !isInFocus) ? "section-error-message" : "section-error-message-hidden";
        return (
            <div className="form-section-container">
                <label className="section-name" htmlFor={id}>{sectionName}</label>
                <input
                    className={inputClass}
                    id={id}
                    placeholder={placeHolder}
                    onFocus={this.updateOnFocus}
                    type="email"
                    onBlur={this.validateEmail}
                />
                <div className={errorMessageClass}>{error}</div>
            </div>
        );
    }
}

FormSectionInputEmail.propTypes = {
    getInput: PropTypes.func,
    id: PropTypes.string,
    placeHolder: PropTypes.string,
    sectionName: PropTypes.string
}

export default FormSectionInputEmail;