import React, { Component } from 'react';
import '../css/page-buttons.css';

import OutlinedButton from './OutlinedButton';
import BackToTopButton from './BackToTopButton';

var excludedPages = "/careers";

class PageButtons extends Component {
    constructor() {
        super()
        this.state = {
            isPageIncluded: !(window.location.href).includes(excludedPages),
            isPageButtonsVisible: false,
        }

        this.handleToggleButtons = this.handleToggleButtons.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
      
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }
      
    listenToScroll = () => {
        if(window.scrollY === 0) {
            this.handleToggleButtons()
        } else {
            if(!this.state.isPageButtonsVisible) {
                this.handleToggleButtons()
            }
        }
    }

    handleToggleButtons() {
        this.setState((state) => {
            return { isPageButtonsVisible: !state.isPageButtonsVisible }
       })
    }

    shouldComponentUpdate(nextState) {
        return this.state.isPageButtonsVisible !== nextState.isPageButtonsVisible;
    }

    render() {
        const { 
            isPageButtonsVisible 
        } = this.state;

        return (
            isPageButtonsVisible &&
                <div className="page-buttons-container animated fadeIn">
                    {/* <div className="outlined-button">
                        <div className="btn btn-primary btn-outline" onClick={() => {this.props.openModal()}}>Founder's Club</div>
                    </div> */}
                    <OutlinedButton text="Schedule A Visit" linkTo="./contact#contact-us-form"/>
                    <BackToTopButton />
                </div>
        );
    }
}
 
export default PageButtons;