import React from 'react';

import Divider from '../components/Divider';
import SmallBanner from '../components/SmallBanner';
import SectionHeader from '../components/SectionHeader';
import PictureAndText from '../components/PictureAndText';
import PictureAndBullets from '../components/PictureAndBullets';
import ServiceSectionHeader from '../components/ServiceSectionHeader';
import { getSpecificService, ServicesConstants } from '../helperJS/services';
 
const MemoryCare = () => {
    let memoryCare = getSpecificService(ServicesConstants.MemoryCare); 

    return (
        <React.Fragment>
            <SmallBanner 
                imgSrc={require("../assets/shared/services-banner.jpg")}
                webpSrc={require("../assets/shared/services-banner.webp")}
                altText="Crestview Senior Living Memory Care"
            />
            <ServiceSectionHeader text="Memory Care" />
            <PictureAndText 
                text={memoryCare.aboutText}
                imgSrc={require("../assets/memory-care/about-text-pic.jpg")}
                webpSrc={require("../assets/memory-care/about-text-pic.webp")}
                altText="Crestview Senior Living Memory Care"
                textFirst={false}
            />
            <Divider />
            <SectionHeader text="Service & Amenities" />
            <PictureAndBullets
                bulletsList={memoryCare.serviceAndAmenityBullets}
                bulletsListLinks={memoryCare.serviceAndAmenityBulletsLinks}
                imgSrc={require("../assets/memory-care/service-and-amenities.jpg")}
                webpSrc={require("../assets/memory-care/service-and-amenities.webp")}
                altText="Crestview Senior Living Memory Care Services and Amenities"
                bulletsFirst={true}
            />
        </React.Fragment>
    );
}
 
export default MemoryCare;