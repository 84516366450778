import React from 'react';
import '../css/divider.css'; 

const Divider = () => {
    return (
        <div className="divider-container" >
            <hr className="divider"/>
            <hr className="divider"/>
        </div>
    );
}
 
export default Divider;