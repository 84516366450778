import React from 'react';
import '../css/text.css';

const CustomTextBox = ({ text }) => {
    return (
        <p className="custom-text-container animated fadeIn">
            {text}
        </p>
    );
}

export default CustomTextBox; 