import React, { Component } from 'react';
import { Pages } from '../helperJS/enums';

import { Helmet } from 'react-helmet';

class ReactHelmetWrapper extends Component {
    constructor(props) {
        super(props);

        this.getTitleForPage = this.getTitleForPage.bind(this);
    }

    getTitleForPage(page) {
        let title = "Crestview Senior Living";
        let titleWithPage;
        switch(page) {
            case Pages.Home: titleWithPage = title.concat(" | Home"); break;
            case Pages.AssistedLiving: titleWithPage = title.concat(" | Assisted Living"); break;
            case Pages.MemoryCare: titleWithPage = title.concat(" | Memory Care"); break;
            case Pages.RespiteCare: titleWithPage = title.concat(" | Respite Care"); break;
            case Pages.IndependentCottages: titleWithPage = title.concat(" | Independent Cottages"); break;
            case Pages.FloorPlans: titleWithPage = title.concat(" | Floor Plans"); break;
            case Pages.ContactUs: titleWithPage = title.concat(" | Contact Us"); break;
            case Pages.Careers: titleWithPage = title.concat(" | Careers"); break;
            case Pages.Covid19: titleWithPage = title.concat(" | Covid-19"); break;
            case Pages.Dining: titleWithPage = title.concat(" | Dining"); break;
            default: titleWithPage = title; break;
        }
        return titleWithPage.concat(" | Cabot, Arkansas");
    }

    getCurrentUrl() {
        let hostname = "https://crestviewsl.com";
        let pathname = window.location.pathname;
        return hostname.concat(pathname);
    }

    render() {
        const {
            page
        } = this.props;

        return (
            <Helmet>
                <title>{this.getTitleForPage(page)}</title>
                <meta name="og:url" content={this.getCurrentUrl()} />
                <meta name="og:title" content={this.getTitleForPage(page)} />
                <link rel="canonical" href={this.getCurrentUrl()} />
            </Helmet>
        );
    }
}

export default ReactHelmetWrapper;