import React, { Component } from 'react';
import '../css/careers-page.css';
import '../css/coming-soon-banner.css'; 

import Divider from '../components/Divider';
import CareersForm from '../components/CareersForm';
import SectionHeader from '../components/SectionHeader';
import PictureAndText from '../components/PictureAndText';
import ParallaxBanner from '../components/ParallaxBanner';
import JobExpansionPanel from '../components/JobExpansionPanel';

import { Pages } from '../helperJS/enums';
import { availablePositions } from '../helperJS/jobs';

class Careers extends Component {
	constructor() {
		super();
        this.scrollToRef = React.createRef();
        
        this.scrollToCareersForm = this.scrollToCareersForm.bind(this);
	}

	// componentDidMount() {
	// 	this.scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    // }

    scrollToCareersForm() {
        this.scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    
    render() {
        return (
            <React.Fragment>
                <ParallaxBanner
                    page={Pages.Careers}
                    altText="Crestview Senior Living - Careers"
                    titleText="Careers"
                />
                <div className="coming-soon-banner-container">
                    <div className="coming-soon-header">Join Our Team!</div>
                    <div className="coming-soon-subheader">Send us your resume now if you're interested!!</div>
                    <div className="coming-soon-button-container">
                        <div className="apply-now-button">
                            <div className="outlined-button">
                                <div 
                                    onClick={this.scrollToCareersForm} 
                                    style={{ width: "inherit"}}
                                    className="btn btn-primary btn-outline"
                                >
                                        Apply Now
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <SectionHeader text="Come work with us!" /> */}
                <PictureAndText 
                    text="A Culture Of Care
                    newline
                    Our team is our greatest asset
                    newline
                    Our team is a crucial and important part of our resident's days, which means it's important to us. That's why when it comes to hiring, Crestview Senior Living seeks only the very best--those who share our passion for hospitality and care.
                    newline
                    At Crestview Senior Living, we offer opportunities at all levels. We recruit experienced and licensed nurses, well trained caregivers, and management positions, all with room to grow"
                    // newline
                    // Those who join our family tend to stay--and we strive to make that stay worthwhile, with exceptional healthcare benefits, financial assistance, and professional growth opportunities. We're proud to have industry-leading tenure, with industry-leading employee satisfaction.
                    imgSrc={require("../assets/careers/working-together.jpg")}
                    webpSrc={require("../assets/careers/working-together.webp")}
                    altText="All Hands In - Working Together"
                    textFirst={true}
                />
                <Divider />
                <SectionHeader text="Positions Available" />
                <div style={{ padding: '20px 0px 80px 0px' }}>
                    { availablePositions.length 
                        ? availablePositions.map((job, index) => {
                            return <JobExpansionPanel 
                                        job={job} 
                                        scrollToForm={this.scrollToCareersForm}
                                        key={index} 
                                    />
                        })
                        : <p className="no-job-postings">We currently do not have any job postings available.  Please check back later.</p>
                    }
                </div>
                <Divider />
                <div className="careers-page-form-container" id="careers-form-container" ref={this.scrollToRef}>
                    <div className="careers-title">Send Us Your Resume</div>
                    <CareersForm />
                </div>
            </React.Fragment>
        );
    }
}
 
export default Careers;