import React from 'react';
import '../css/gallery-image.css';

import { Pages } from '../helperJS/enums';
import { ItemComponents } from '../helperJS/enums';
import { galleryImages, kitchenImages } from '../helperJS/galleryImages';

import Divider from '../components/Divider';
import ListToRows from '../components/ListToRows';
import VideoPlayer from '../components/VideoPlayer';
import SectionHeader from '../components/SectionHeader';
import ParallaxBanner from '../components/ParallaxBanner';

const Gallery = () => {
    return (
        <React.Fragment>
            <ParallaxBanner
                page={Pages.Gallery}
                altText="Crestview Senior Living - Gallery"
                titleText="Gallery"
            />
            <SectionHeader text="A Glimpse into our Community" />
            <SectionHeader text="More Pictures of our Beautiful Community to Come!!" isSubHeader={true}/>
            <div className="gallery-section-container">
                <ListToRows 
                    itemComponentType={ItemComponents.Gallery}
                    listToConvert={galleryImages}
                    numOfColumns={4}
				/>
            </div>
            <Divider />
            {/* <div className="gallery-section-container">
                <ListToRows 
                    itemComponentType={ItemComponents.Gallery}
                    listToConvert={kitchenImages}
                    numOfColumns={3}
				/>
            </div>
            <Divider /> */}
            <VideoPlayer 
                videoHeaderText="Check out a virtual tour of our community!"
                url='https://d86mj0n906ty0.cloudfront.net/crestview_virtual_tour.mp4' 
                playing={true}
                muted={true}  
                loop={true}  
            />
        </React.Fragment>
    );
}
 
export default Gallery;