import React from 'react';
import '../css/picture-and-bullets.css'

import WebpImage from './WebpImage';
 
const PictureAndBullets = ({ bulletsList, bulletLinksList, imgSrc, webpSrc, altText, bulletsFirst }) => {

    return (
        <div className="picture-and-bullets-container animated fadeIn">
            <div className="bullet-list-container">
                <ul type="disc">
                    {
                        bulletsList.map ( bullet => {
                            return bullet === "Emergency call system pendants"
                            ? <li key={bullet}>
                                    <a target="_blank" href="https://crestview-assets.s3.us-east-2.amazonaws.com/HM_Single_Button_Pendant_Specs.pdf">{bullet}</a>
                                </li>
                            : <li key={bullet}>{bullet}</li>
                        })
                    }
                </ul>
            </div>
            <div className="picture">
                <WebpImage
                    imgSrc={imgSrc}
                    webpSrc={webpSrc}
                    altText={altText}
                />
            </div>
        </div>
    );
}
 
export default PictureAndBullets;