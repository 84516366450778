import React from 'react';

import { Pages } from '../helperJS/enums';
import { ItemComponents } from '../helperJS/enums';
import { kitchenImages } from '../helperJS/galleryImages';
import '../css/webp-image.css';

import Divider from '../components/Divider';
import ListToRows from '../components/ListToRows';
import Iframe from '../components/Iframe';
import WebpImage from '../components/WebpImage';
import SectionHeader from '../components/SectionHeader';
import ParallaxBanner from '../components/ParallaxBanner';

const Dining = () => {
    return (
        <React.Fragment>
            <ParallaxBanner
                page={Pages.Dining}
                altText="Crestview Senior Living - Dining"
                titleText="Dining"
            />
            <SectionHeader text="Our Gourmet Dining Experience" />
            <div className="best-meals-and-dining-container">
                <div className="best-meals-and-dining">
                    <WebpImage 
                        webpSrc={require("../assets/shared/best-meals-and-dining.webp")}
                        imgSrc={require("../assets/shared/best-meals-and-dining.jpg")}
                        altText="crestview senior living"
                    />
                </div>
            </div>
            <SectionHeader text="Enjoy restaurant style, chef prepared, gourmet dining options in our Grand Hall or opt for in-room delivery. Our meals are healthy, nutritious and balanced, and designed to improve overall health. Our culinary team is happy to work with you regarding your dietary requirements." isSubHeader={true}/>
            <Iframe 
                src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=xmfde668a6"
                width="80%" 
                height="300" 
                seamless="seamless" 
                scrolling="no" 
                frameBorder="0"
            />
            <Divider />
            <div className="gallery-section-container">
                <ListToRows 
                    itemComponentType={ItemComponents.Gallery}
                    listToConvert={kitchenImages}
                    numOfColumns={3}
				/>
            </div>
            <Divider />
        </React.Fragment>
    );
}
 
export default Dining;