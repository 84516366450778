import React from 'react';
import '../css/section-header.css';

const subHeaderStyle = { fontSize: "1.5vw", color: "#ab926e" }
const mobileSubHeaderStyle = { fontSize: "4vw", color: "#ab926e", margin: "20px" }


const SectionHeader = ({ text, isSubHeader }) => {
    const isMobile = window.innerWidth <= 576;

    var subHeaderStyleToApply = 
        isSubHeader
            ? isMobile 
                ? mobileSubHeaderStyle
                : subHeaderStyle
            : {};
    return (
        <div 
            className={`section-title-container animated fadeIn}`}
            style={subHeaderStyleToApply}>
            {text}
        </div>
    );
}
 
export default SectionHeader;