export const CovidTexts = {
    headerText: "Crestview Senior Living continues to be unwavering in our mission to compassionately serve our residents with quality care and excellence. The health and wellness of our residents and employees remain our highest priorities. We continue to be in close contact with local, state and federal health authorities, following their guidance, mandates and reporting requirements. The residents and families we serve are very important to us and we will continue to move forward with compassion, and an over-abundance of caution and quality care.",
    visitorGuidlinesText: "Families are being reunited under ongoing guidance released by the Arkansas State Department of Health for indoor and/or outdoor visitation. We understand that residents and families are anxious to connect; Crestview Senior Living is utilizing policies and protocols based on this guidance to keep our residents and employees safe during these visits. Visitors will be asked to sign-in, wear a face mask and adhere to proper social distancing, along with other criteria. Residents must also wear a face mask and meet additional criteria during the visit. Visitation guidance may change and is influenced by numerous factors which may include the spread of COVID-19 within the local community. While there are some restrictions involved, we look forward to connecting families and loved ones.",
};

export const CovidTextsGuidelines = [
    {
        header: "COVID-19 Testing",
        text: "Daily, residents and employees are assessed for signs and symptoms of COVID-19 and have their temperature taken. Employees exhibiting symptoms are immediately sent home until they meet the return to work guidelines outlined by the Center for Disease Control (CDC). Residents exhibiting symptoms are tested and placed in special precautions while they await their results. \n Early detection of the virus is imperative to reducing the exposure risk to other residents and to staff. Residents and employees are tested at a frequency in accordance to state and federal mandates. Additional protective precautions, assessments, monitoring and treatment protocols are put in place to serve residents who test positive.",
    },
    {
        header: "COVID-19 Screening of Employees and Essential Medical Providers",
        text: "Limiting the number of people who enter our resident’s home and rigorously screening those who do enter, are important strategies to minimizing the exposure to COVID-19. \n Screening includes a questionnaire about COVID-19 symptoms and a temperature reading. Any employee, visitor or medical provider that triggers screening concerns, is not permitted to work, visit or provide service that day. Employees, visitors and medical providers are expected to wear personal protective equipment such as a mask, eye protection, gloves, gowns, etc. and follow facility infection control policies and procedures while in the community.",
    },
    {
        header: "Admissions Process",
        text: "Prospective residents and families may tour our community in person while adhering to COVID-19 guidelines. Virtual tour options are available. Treatment consents can be signed electronically and shared via email if necessary. To ensure the safety of our residents, each new resident is screened for COVID-19 symptoms prior to admission. Additionally, if a new resident is not fully vaccinated we welcome them into an enhanced infection control observation for their first two weeks. New unvaccinated residents dine and participate in life enriching activities in their room during the first two weeks.",
    },
    {
        header: "Memory Care",
        text: "Crestview’s memory care neighborhood is operating within the CDC’s COVID-19 recommendations. \n For the health of our residents we are striving every day to honor state, local and federal guidelines, monitoring social distancing and encouraging residents to wear masks, as tolerated. Because COVID-19 restrictions may disrupt some of the daily activities and routines which enhance the lives our memory care residents, our highly trained memory care team is well equipped to observe for, and recognize any unusual, behavioral or symptom-based expressions that would denote a decrease in our residents’ psychosocial or emotional quality of life. \n Our memory care employees are going above and beyond to continue to create meaningful moments with our residents every day. Our team is continuing to prioritize wellness and engagement along with excellent infection control practices.",
    },
    {
        header: "Cleaning Protocol",
        text: "One of the most important ways to limit the spread of the COVID-19 virus is thorough and frequent cleaning of surfaces. \n Crestview Senior Living has implemented enhanced housekeeping procedures to ensure a clean and sanitized environment. All housekeeping personnel are educated on infection control practices and cleaning strategies specific to COVID-19. Each housekeeper uses specialized, CDC approved disinfectant to kill the virus on surfaces. High touch areas such as light switches, doorknobs and call lights are sanitized multiple times per day by several team members. A Quality Assurance tool is used to validate that enhanced housekeeping procedures are being followed for every room and common area.",
    },
    {
        header: "Dining Experience",
        subheader: "*Our dining room activity has returned with in-room dining offered as needed.",
        text: "At Crestview Senior Living, we take pride in creating a pleasurable dining experience prepared by highly a skilled team of culinary experts. We understand that the social dining experience has historically been a very important part of our residents’ day. During the COVID-19 pandemic, we are providing in-room dining delivered to our residents’ suites. Additionally, we have snack and beverage carts circulating to help residents stay nourished and hydrated. Safety is of the utmost concern but so are the nutritional needs and satisfaction of our residents. As our phased resumption of operations plan evolves, and as state and local guidelines allow, limited dining room activity will commence with socially distanced seating.",
    },
    {
        header: "Communication",
        text: "We understand that connecting our residents with their loved one is incredibly important during COVID-19 visitor restrictions. We offer access to communication devices and extra support to families who desire a visit with their loved ones through alternative methods such as telephone, email, text, video chat or social media during this challenging public health concern."
    }
];

Object.freeze(CovidTexts);
Object.freeze(CovidTextsGuidelines);