export const jobTypes = {
    FullTime: "Full-time",
    PartTime: "Part-time",
    FullTimePartTimePRN: "Full-time/Part-time/PRN"
};

export const availablePositions = [
    // {
    //     jobTitle: "Registered Nurse - RN",
    //     jobDescription: "Weekend options available. Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTimePartTimePRN,
    // },
    // {
    //     jobTitle: "Certified Nursing Assistant - CNA",
    //     jobDescription: "Weekend options available. Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTimePartTimePRN,
    // },
    // {
    //     jobTitle: "Licensed Practical Nurse - LPN",
    //     jobDescription: "Weekend options available. Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTimePartTimePRN,
    // },
    // {
    //     jobTitle: "Lead AM Dietary Cook",
    //     jobDescription: "We have an exciting opportunity for a Lead AM Dietary Cook with prior experience working in an Assisted Living Community. It is our Mission to hire responsible, compassionate and self-motivated employees.   The main function of the dietary cook is to prepare breakfast and lunch that not only taste delicious but is also pleasing to the eye.  Our employees will perform these duties under direct supervision of the CDM\CFPP to ensure established sanitation, safety and dietary procedures in a timely manner. An excellent cook must be able to follow instructions in cooking and delivering well-prepared meals. They must be deft in moving around the kitchen and apt in multi-tasking. Experience in using various ingredients and cooking techniques is important. The goal is to help preserve and enhance our reputation so we can expand our clientele.",
    //     qualifications: [
    //         "Proven experience as a cook",
    //         "Experience in using cutting tools, cookware, and bakeware",
    //         "Knowledge of various cooking procedures and methods (grilling, boiling, baking, etc.)",
    //         "Ability to follow all sanitation procedures",
    //         "Ability to work in a team environment",
    //         "Very good communication skills",
    //         "Excellent physical condition and stamina",
    //         "High school diploma or equivalent; Diploma from a culinary school will be an advantage",
    //         "Must be able to lift 50+ pounds",
    //         "Ability to remain calm under stress",
    //         "Visual and Hearing ability is essential",
    //         "Adhere to departmental dress code and good hygiene and safety practices",
    //     ],
    //     jobType: jobTypes.FullTime,
    // },
    // {
    //     jobTitle: "Maintenance Manager",
    //     jobDescription: "Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTime,
    // },
    // {
    //     jobTitle: "Dietary Manager",
    //     jobDescription: "Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTime,
    // },
    // {
    //     jobTitle: "Business Office Manager",
    //     jobDescription: "Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTime,
    // },
    // {
    //     jobTitle: "Activities Director",
    //     jobDescription: "Send us your resume for more information regarding this position.",
    //     qualifications: [],
    //     jobType: jobTypes.FullTime,
    // },
];

Object.freeze(availablePositions);