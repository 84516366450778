import React from 'react';
import '../css/iframe.css';

const Iframe = ({ src, width, height, frameBorder, scrolling, seamless }) => {
    return (
        <div className="iframe-container">
            <iframe 
                className="iframe"
                src={src}
                width={width}
                height={height}
                seamless={seamless}
                scrolling={scrolling}
                frameBorder={frameBorder}
                allowFullScreen 
            />
        </div>

    );
}
 
export default Iframe;