export const offers = [
    {
        header: "EXPERT CARE TEAM",
        text: "Our skilled and compassionate team provides a warm and friendly community for our residents and their families.  We consider all of our residents to be family and will always be treated with love and compassion.",
    },
    {
        header: "OUR COMMUNITY",
        text: "Nestled in a quite area in Cabot, Arkansas, we are still just minutes away from shopping, churches and hospitals.  Crestview Senior Living provides the perfect combination of attention, active living and comfort.",
    },
    {
        header: "STYLISH, ATTRACTIVE FLOOR PLANS",
        text: "Our upscale community offers a variety of upscale finishes and luxurious features.  Our homes offer a roomy floor plans with plenty of room to call it your home.  Learn more about our floor plans.",
    },
    {
        header: "ACTIVITIES & EVENTS",
        text: "There is always something to keep you busy at Crestview.  We have designed activities to keep your body and brain active.  We know you will never get bored with us, whether its low-keyed games or lively dancing.",
    },
    {
        header: "SPACIOUS, ELEGANT COMMUNITY",
        text: "Our state of the art community includes many entertaining areas, including our theater and activity rooms, as well as our spacious and luxurious suites for residents.",
    },
    {
        header: "GOURMET DINING",
        text: "Enjoy restaurant style, chef prepared, gourmet dining options in our Grand Hall or opt for in-room delivery.  Our meals are healthy, nutritious and balanced, and designed to improve overall health.  Our culinary team is happy to work with you regarding your dietary requirements.",
    },
];

Object.freeze(offers);