import React, { Component } from 'react';
import '../../css/form-section.css';

class FormSectionTextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        }
        this.callBackForTextAreaField = this.callBackForTextAreaField.bind(this);
    }

    callBackForTextAreaField(e) {
        this.setState({ text: e.target.value }, () => {
            this.props.getInputToPass(this.state.text, this.props.id);
        })
    }

    render() {
        const {
            sectionName,
            placeHolder,
            isHeightResizable,
            id,
        } = this.props;

        let heightStyle = isHeightResizable &&  { height: 'auto' }
        return (
            <div className="form-section-container" style={heightStyle}>
                <label className="section-name" htmlFor={id}>{sectionName}</label>
                <textarea 
                    name={sectionName}
                    id={id}
                    cols="20"
                    rows="5"
                    className="section-input"
                    placeholder={placeHolder}
                    style={{ height: 'auto' }}
                    onChange={this.callBackForTextAreaField}
                />
            </div>
        );
    }
}

export default FormSectionTextArea;