import React, { Component } from 'react';
import '../css/contact-page.css';


import ContactForm from '../components/ContactForm';
import ParallaxBanner from '../components/ParallaxBanner';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';

import { Pages } from '../helperJS/enums'
var iconStyle = { color: "#cccccc", fontSize: 30, margin: "10px" }
var googleMapStyle = { border: "0" }

class Contact extends Component {
	constructor(props) {
		super(props);
		this.scrollToRef = React.createRef();
	}

	componentDidMount() {
		this.scrollToRef.current.scrollIntoView({behavior: 'smooth'})
	}

	render() {
		const {
			openModal
		} = this.props;

	    return (
			<React.Fragment>
				<ParallaxBanner
					page={Pages.ContactUs}
					altText="Crestview Senior Living Contact Us"
					titleText="Contact Us"
				/>
				<div className="contact-us-page-container" id="contact-us-form" ref={this.scrollToRef}>
					<div className="contact-us-form-container">
						<div className="contact-us-title">Contact Us</div>
						<ContactForm />
					</div>
					<div className="contact-us-info-container">
						<div className="contact-us-info">
							<div className="crestview-title">Crestview Senior Living</div>
							<div className="contact-type-section">
								<div className="contact-type-container">
									<LocationOnIcon style={iconStyle}/>
									<a className="contact-content" href="https://goo.gl/maps/y8XibyZJJfytHjeG6">
										3310 West Main Street, <br /> Cabot, AR 72023 <br /> United States
									</a>
								</div>
								<div className="contact-type-container">
									<PhoneEnabledIcon style={iconStyle}/>
									<a className="contact-content" href="tel://5014226290">+1 501 422 6290</a>
								</div>
							</div>
						</div>
						{/* <div className="google-map-container">
							<div className="google-map-medium">
								<iframe 
									src="https://bit.ly/google-map-large" 
									width="600"
									height="450"
									style={googleMapStyle}
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
								/>
							</div>
							<div className="google-map-small">
								<iframe
									src="https://bit.ly/google-map-small"
									width="400"
									height="300"
									style={googleMapStyle}
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
								/>
							</div>
						</div> */}
						{/* <div className="google-map-container">
							<div className="google-map-medium">
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.9169838543344!2d-92.06265048476016!3d34.98374408036294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d2ee7bece63977%3A0x5ab4e503e7b643cc!2s3310%20W%20Main%20St%2C%20Cabot%2C%20AR%2072023!5e0!3m2!1sen!2sus!4v1589060527655!5m2!1sen!2sus" width="600" height="450" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
							</div>
							<div className="google-map-small">
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.9169838543403!2d-92.06265048476622!3d34.983744080362804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d2ee7bece63977%3A0x5ab4e503e7b643cc!2s3310%20W%20Main%20St%2C%20Cabot%2C%20AR%2072023!5e0!3m2!1sen!2sus!4v1589253502520!5m2!1sen!2sus" width="400" height="300" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
							
							</div>
						</div> */}
					</div>
				</div>
		  </React.Fragment>
		);	
	}
}
 
export default Contact;