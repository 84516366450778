import React from 'react';
import '../css/coming-soon-banner.css'; 

import CustomTextBox from '../components/CustomTextBox'
import SectionHeader from '../components/SectionHeader';
import CovidExpansionPanel from '../components/CovidExpansionPanel';
import ServiceSectionHeader from '../components/ServiceSectionHeader';
import { CovidTexts, CovidTextsGuidelines } from '../helperJS/covid19Text';

const Covid19Page = () => {
    let isMobile = window.innerWidth <= 576;

    return (
        <React.Fragment>
            { !isMobile && <div style={{ marginTop: '180px' }}/> }
            
            <div className="coming-soon-banner-container">
                <div className="coming-soon-subheader">As of March 4, 2021 the State of Arkansas has lifted the COVID restrictions for assisted living facilities. Crestview Senior Living will continue to operate with certain precautions such as employee and visitor screenings, masks, and additional cleaning protocols. Visitors can now freely see residents in their apartments. </div>
            </div>
            <ServiceSectionHeader text="Our Pandemic Response to COVID-19" />
            <CustomTextBox text={CovidTexts.headerText} />
            
            <SectionHeader text="Visitor Guidelines" />
            <CustomTextBox text={CovidTexts.visitorGuidlinesText} />
            
            <ServiceSectionHeader text="What Residents and Families Need to Know" />
            {
                CovidTextsGuidelines.map((covidText, index) => {
                    return <CovidExpansionPanel 
                        covidText={covidText}
                        key={index} 
                    />
                })
            }
            <div style={{ marginBottom: "100px" }}/>
        </React.Fragment>
    );
}
 
export default Covid19Page;