import React, { Component } from 'react';
import '../css/webp-image.css'; 

import Modal from '@material-ui/core/Modal';
import CloseIcon from '@mui/icons-material/Close';
// import PrintIcon from '@material-ui/icons/Print';
// import ShareIcon from '@material-ui/icons/Share';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

class WebpImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isHovered: false
        }

        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleMouseEnter = this.handleMouseEnter.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
    }

    handleOpen() {
        this.props.expandable &&
            this.setState({ showModal: true });
    };
  
    handleClose() {
        this.props.expandable &&
            this.setState({ showModal: false });    
    };

    handleMouseEnter() {
        this.props.expandable &&
            this.setState({ isHovered: true });
    };
    
    handleMouseLeave() {
        this.props.expandable &&
            this.setState({ isHovered: false });
    };

    render() {
        const {
            altText,
            imgSrc,
            webpSrc,
            isLogo,
            expandable,
            isClickable,
        } = this.props;

        const {
            isHovered
        } = this.state;

        let logoStyle = isLogo ? { width: "50%" } : {};
        let hoverStyle = isHovered ? { opacity:0.3, cursor: 'pointer' } : isClickable ? { opacity:1, cursor: 'pointer' } : { opacity:1, cursor: 'auto' }


        return (
            <React.Fragment>
                {
                    expandable &&
                        <React.Fragment>
                            <ZoomInIcon className="zoom-in-icon" style={{fontSize: '50px'}}/>
                            <Modal
                                className="modal-container"
                                open={this.state.showModal}
                                onClose={() => this.handleClose()}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <React.Fragment>
                                    {/* <PrintIcon className="print-icon" style={iconStyle} /> */}
                                    {/* <ShareIcon className="share-icon" style={iconStyle} /> */}
                                    <CloseIcon 
                                        className="close-button-icon" 
                                        style={{ fontSize: 50 }}
                                        onClick={() => this.handleClose()}
                                    />
                                    <picture className="expanded-webp-picture-container">
                                        <source type="image/webp" srcSet={webpSrc.default} />
                                        <img className="img-container" 
                                            src={imgSrc.default} 
                                            alt={altText} 
                                            style={logoStyle}
                                        />
                                    </picture>
                                </React.Fragment>
                            </Modal>
                        </React.Fragment>
                }
                <picture className="webp-picture-container"
                    style={hoverStyle}
                    onMouseEnter={() => this.handleMouseEnter()}
                    onMouseLeave={() => this.handleMouseLeave()}
                    onClick={() => this.handleMouseLeave()} >
                    <source type="image/webp" srcSet={webpSrc.default} />
                    <img className="img-container" 
                        src={imgSrc.default} 
                        alt={altText} 
                        style={logoStyle}
                        onClick={() => this.handleOpen()} />
                </picture>  
            </React.Fragment>
        )
    }
}

export default WebpImage;