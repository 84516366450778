import React from 'react';

import Divider from '../components/Divider';
import SmallBanner from '../components/SmallBanner';
import AlertBanner from '../components/AlertBanner';
import SectionHeader from '../components/SectionHeader';
import PictureAndText from '../components/PictureAndText';
import PictureAndBullets from '../components/PictureAndBullets';
import ServiceSectionHeader from '../components/ServiceSectionHeader';
import { getSpecificService, ServicesConstants } from '../helperJS/services';

const IndependentCottages = () => {
    let independentCottages = getSpecificService(ServicesConstants.IndependentCottages); 

    return (
        <React.Fragment>
            <SmallBanner 
                imgSrc={require("../assets/shared/services-banner.jpg")}
                webpSrc={require("../assets/shared/services-banner.webp")}
                altText="Crestview Senior Living Independent Cottages"
            />
            <AlertBanner 
                header="Phase 2 - Opening this summer!"
                subheader="Send us a message if you're interested in taking a tour"
                buttonText="Notify Me!"
                buttonLink="/contact"
            />
            <ServiceSectionHeader text="Independent Cottages" />
            <PictureAndText 
                text={independentCottages.aboutText}
                imgSrc={require("../assets/floor-plans/cottage-floor-plan.jpg")}
                webpSrc={require("../assets/floor-plans/cottage-floor-plan.webp")}
                altText="Crestview Senior Living Independent Cottages"
                textFirst={true}
            />
            <Divider />
            <SectionHeader text="Service & Amenities" />
            <PictureAndBullets
                bulletsList={independentCottages.serviceAndAmenityBullets}
                imgSrc={require("../assets/independent-cottages/duplex-cottage-exterior.jpg")}
                webpSrc={require("../assets/independent-cottages/duplex-cottage-exterior.webp")}
                altText="Crestview Senior Living Independent Cottages Services and Amenities"
                bulletsFirst={false}
            />
        </React.Fragment>
    );
}
 
export default IndependentCottages;