import React from 'react';
import { Pages } from '../helperJS/enums'
import FullWidthTabs from '../components/FullWidthTabs';
import ParallaxBanner from '../components/ParallaxBanner';

const FloorPlans = ({ openModal }) => {
    return (
        <React.Fragment>
            <ParallaxBanner
                page={Pages.FloorPlans}
                altText="Crestview Senior Living - Floor Plans"
                titleText="Floor Plans"
            />

            {/* <div className="coming-soon-banner-container">
                <div className="coming-soon-header">Join Our Exlusive Founder's Club Now!</div>
                <div className="coming-soon-subheader">Be one of the first members in our community and enjoy the amazing benefits</div>
                <div className="coming-soon-button-container">
                    <div className="btn btn-primary btn-outline" onClick={() => {openModal()}}>Tell me more!</div>
                </div>
            </div> */}

            <FullWidthTabs />
        </React.Fragment>
    );
}
 
export default FloorPlans;