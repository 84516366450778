import React from 'react';

import Divider from '../components/Divider';
import SmallBanner from '../components/SmallBanner';
import SectionHeader from '../components/SectionHeader';
import PictureAndText from '../components/PictureAndText';
import PictureAndBullets from '../components/PictureAndBullets';
import ServiceSectionHeader from '../components/ServiceSectionHeader';
import { getSpecificService, ServicesConstants } from '../helperJS/services';

const RespiteCare = () => {
    let respiteCare = getSpecificService(ServicesConstants.RespiteCare);
    
    return (
        <React.Fragment>
            <SmallBanner 
                imgSrc={require("../assets/shared/services-banner.jpg")}
                webpSrc={require("../assets/shared/services-banner.webp")}
                altText="Crestview Senior Living Respite Care"
            />
            <ServiceSectionHeader text="Respite Care" />
            <PictureAndText 
                text={respiteCare.aboutText}
                imgSrc={require("../assets/short-term-care/about-text-pic.jpg")}
                webpSrc={require("../assets/short-term-care/about-text-pic.webp")}
                altText="Crestview Senior Living Respite Care"
                textFirst={true}
            />
            <Divider />
            <SectionHeader text="Service & Amenities" />
            <PictureAndBullets
                bulletsList={respiteCare.serviceAndAmenityBullets}
                bulletsListLinks={respiteCare.serviceAndAmenityBulletsLinks}
                imgSrc={require("../assets/short-term-care/service-and-amenities.jpg")}
                webpSrc={require("../assets/short-term-care/service-and-amenities.webp")}
                altText="Crestview Senior Living Respite Care Services and Amenities"
                bulletsFirst={false}
            />
        </React.Fragment>
    );
}
 
export default RespiteCare;