import React from 'react';
import { Parallax } from 'react-parallax';
import { Pages } from '../helperJS/enums'
import { getImageExtension, Extensions } from '../helperJS/browserDetection';
import '../css/parallax-banner.css';

const getBannerImage = (page) => {
    let isWebp = getImageExtension('jpg') === Extensions.WEBP;

    switch(page) {
        case Pages.Home: return isWebp ? require('../assets/home/banner.webp').default : require('../assets/home/banner.jpg').default
        case Pages.Careers: return isWebp ? require('../assets/careers/banner.webp').default : require('../assets/careers/banner.jpg').default
        case Pages.ContactUs: return isWebp ? require('../assets/contact/banner.webp').default : require('../assets/contact/banner.jpg').default
        case Pages.FloorPlans: return isWebp ? require('../assets/floor-plans/banner.webp').default : require('../assets/floor-plans/banner.jpg').default
        case Pages.Gallery: return isWebp ? require('../assets/gallery/banner.webp').default : require('../assets/gallery/banner.jpg').default
        case Pages.Dining: return isWebp ? require('../assets/dining/banner.webp').default : require('../assets/dining/banner.jpg').default
    }
}

const ParallaxBanner = ({ page, altText, titleText, subTextLineOne, subTextLineTwo }) => {
    let centerStyle = subTextLineOne ? {'justifyContent': 'space-evenly'} : {'justifyContent': 'center'};
    return (
        <Parallax
            // blur={10}
            bgImage={getBannerImage(page)}
            bgImageAlt={altText}
            strength={250}
            >
            <div className="parallax-banner">            
                <div className="text-container" style={centerStyle}>
                    <h1 className="title-text">{titleText}</h1>
                    <div className="sub-text-container">
                        { subTextLineOne && <div className="sub-text">{subTextLineOne}</div> }
                        { subTextLineTwo && <div className="sub-text">{subTextLineTwo}</div> }
                    </div>
                </div>
            </div>
        </Parallax>
    );
}

export default ParallaxBanner;