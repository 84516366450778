import React from 'react';
import '../css/service-section-header.css';

const ServiceSectionHeader = ({ text }) => {
    return (
        <h1 
            className="service-section-title-container animated fadeIn">
            {text}
        </h1>
    );
}

export default ServiceSectionHeader; 