import React, { Component } from "react";
import '../../css/form-section.css'

import DragAndDrop from '../DragAndDrop';
import FileUploadedCard from '../FileUploadedCard';

class FileUpload extends Component {
    // fileToUpload contains the actual file object
    // uploadSuccess becomes true when the file upload is complete
    state = {
        fileToUpload: undefined,
        uploadSuccess: undefined,
        error: undefined
    };

    handleDrop = (newDroppedFiles) => {
        this.setState({
            fileToUpload: newDroppedFiles[0]
        }, () => {
            this.props.getFile(this.state.fileToUpload)
        })
    }

    removeFile = () => {
        this.setState({
            fileToUpload: null
        }, () => {
            this.props.getFile(this.state.fileToUpload)
        });
    }

    render() {
        return (
            <div className="file-upload-container">
                <div className="file-upload-title">
                    Upload Your Resume*
                </div>
                <DragAndDrop handleDrop={this.handleDrop}>
                    <div className="section-input file-upload-section">
                        {
                            this.state.fileToUpload 
                            ? 
                                <FileUploadedCard 
                                    fileName={this.state.fileToUpload.name}
                                    removeFile={this.removeFile}
                                />
                            : (
                                <div className="file-upload-text">
                                    Drag and drop your resume here or&nbsp;
                                    <input
                                        type="file"
                                        id="fileUpload"
                                        onChange={e => {
                                            this.setState({
                                                fileToUpload: e.target.files[0]
                                            }, () => {
                                                this.props.getFile(this.state.fileToUpload)
                                            });
                                        }}
                                    />
                                    <label htmlFor="fileUpload">browse</label>
                                </div>
                            )
                        }

                        <div>
                            {/* <form>
                                <div className="form-group">
                                    {this.state.fileToUpload ? (
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={e => {
                                                this.uploadFile();
                                            }}
                                        >
                                            Upload your file
                                        </button>
                                    ) : null}
                                    <div>
                                        <span>
                                            {this.state.uploadSuccess
                                                ? "File Upload Successfully"
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </DragAndDrop>
            </div>
        );
    }
}

export default FileUpload;