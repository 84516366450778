import React from 'react';

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../components/TabPanel';
import ListToRows from '../components/ListToRows';
import { ItemComponents } from '../helperJS/enums';
import { assistedLivingFloorPlans, memoryCareFloorPlans, independentCottageFloorPlans } from '../helperJS/floorPlans';
import '../css/full-width-tabs.css';

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

export default function FullWidthTabs() {
	const [value, setValue] = React.useState(0);
	let axis = 'x';

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

  return (
    <div className="full-width-tabs-container">
      	<AppBar position="static" color="default">
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="fullWidth"
				aria-label="full width tabs example"
			>
				<Tab label="Assisted Living" {...a11yProps(0)} />
				<Tab label="Memory Care" {...a11yProps(1)} />
				<Tab label="Independent Cottages" {...a11yProps(2)} />
			</Tabs>
     	</AppBar>
		<SwipeableViews
			// axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
			axis='x-reverse'
			index={value}
			onChangeIndex={handleChangeIndex}
			>
			<TabPanel value={value} index={0} dir={axis}>
				<ListToRows 
					itemComponentType={ItemComponents.FloorPlans}
					listToConvert={assistedLivingFloorPlans}
					numOfColumns={3} 
				/>
			</TabPanel>
			<TabPanel value={value} index={1} dir={axis}>
				<ListToRows 
					itemComponentType={ItemComponents.FloorPlans}
					listToConvert={memoryCareFloorPlans}
					numOfColumns={3}
				/>
			</TabPanel>
			<TabPanel value={value} index={2} dir={axis}>
				<ListToRows 
					itemComponentType={ItemComponents.FloorPlans}
					listToConvert={independentCottageFloorPlans}
					numOfColumns={2}
				/>
			</TabPanel>
		</SwipeableViews>
    </div>
  );
}