import React from 'react';
import App from './components/App';
import './index.css';
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App style={{backgroundColor: "#e1e1e1"}}/>, rootElement);
} else {
  render(<App style={{backgroundColor: "#e1e1e1"}}/>, rootElement);
}