import React, { Component } from 'react';
import '../css/list-to-rows.css';

import RowToItems from './RowToItem';

class ListToRows extends Component {
    constructor(props){
        super(props);

        this.getRowComponent = this.getRowComponent.bind(this);
    }

    getRowComponent(list, numOfColumns, itemComponentType, columnIndex) {
        let startIndex = columnIndex * numOfColumns;
        let stopIndex = startIndex + (numOfColumns);
        return <RowToItems
                    itemComponentType={itemComponentType}
                    rowToConvert={list.slice(startIndex, stopIndex)}
                    key={columnIndex}
                />
    }

    render() {
        const {
            numOfColumns,
            listToConvert,
            itemComponentType
        } = this.props;

        let numOfRows = Math.ceil(listToConvert.length / numOfColumns)

        let rowArray = new Array(numOfRows);
        for(var i=0; i<numOfRows;i++) {
            rowArray[i] = i;
        }

        return (
            <div className="row-container">
                {
                    rowArray.map((column) =>
                        this.getRowComponent(listToConvert, numOfColumns, itemComponentType, column)
                    )
                }
            </div>
        )
    }

}

export default ListToRows;