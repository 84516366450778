import React, { Component } from 'react';
import '../css/video-player.css'; 

import ReactPlayer from 'react-player';
import SectionHeader from './SectionHeader';
import VizSensor from 'react-visibility-sensor';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            playVideo: false,
        };

    }

    render() {
        const isMobile = window.innerWidth <= 576;
        let videoWidth = isMobile ? '100%' : '70%';

        const {
            videoHeaderText, 
            url, 
            muted, 
            loop
        } = this.props

        return (
            <div className='video-header-container'>
                <SectionHeader text={videoHeaderText} />
                <VizSensor 
                    partialVisibility
                    offset={{ top:400, bottom: 400 }}
                    onChange={(isVisible) => {
                        this.setState({playVideo: isVisible})
                    }}
                >
                    <div id="video-player" className='video-player-container'>
                        <ReactPlayer
                            className='react-player'
                            url={url}
                            playing={this.state.playVideo}
                            muted={muted}
                            loop={loop}
                            controls={true}
                            width={videoWidth}
                            height='auto'
                        />
                    </div>
                </VizSensor>
            </div>
        );
    }

} 

export default VideoPlayer;