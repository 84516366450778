import React from 'react';
import '../css/gallery-image.css';

import WebpImage from './WebpImage';

const GalleryImage = ({ item: { jpgImage, webpImage, description }}) => {
    return (
        <div className="gallery-image-and-description-container">
            <div className="gallery-image">
                <WebpImage 
                    imgSrc={jpgImage}
                    webpSrc={webpImage}
                    altText={description}
                    expandable={true}
                />
            </div>
            <div className="gallery-description">
                {description}
            </div>
        </div>
    );
}

export default GalleryImage;