import React from 'react';
import '../css/outlined-button.css'

import { NavLink } from 'react-router-dom';
 
const OutlinedButton = ({ text, linkTo }) => {
    return (
        <div className="outlined-button">
            <NavLink to={linkTo} className="btn btn-primary btn-outline">{text}</NavLink>
        </div>

    );
}
 
export default OutlinedButton;