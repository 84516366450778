import React from 'react';
import '../css/file-uploaded-card.css'

import CloseIcon from '@mui/icons-material/Close';

const FileUploadedCard = ({ fileName, removeFile }) => {
    return (
        <div className="file-uploaded-card-container">
            <CloseIcon 
                style={{ fontSize: '30px', cursor: 'pointer' }}
                onClick={removeFile}
            />
            <div>{fileName}</div>
        </div>
    );
}
 
export default FileUploadedCard;