import React, { Component } from 'react';
import '../css/form.css';

import FormSectionInputText from './form/FormSectionInputText';
import FormSectionInputEmail from './form/FormSectionInputEmail';
import FormSectionInputPhone from './form/FormSectionInputPhone';
import FormSectionFileUpload from './form/FormSectionFileUpload';

const PRESIGNED_URL = 'https://api.crestviewsl.com/prod/presigned-url/?fileName=';
const SUBMIT_RESUME_URL = 'https://dpy97nvjnk.execute-api.us-west-2.amazonaws.com/prod/submit-resume-form';

class ContactForm extends Component {
    constructor() {
        super();
        this.state = {
            isSubmitDisabled: false,
            emailInputHasError: true,
            phoneInputHasError: true,
            fnameInputHasError: true,
            lnameInputHasError: true,
            jobPositionInputHasError: true,
            file: null,
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.enableSubmitButton = this.enableSubmitButton.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);
        this.disableSubmitButton = this.disableSubmitButton.bind(this);
    }

    componentDidMount() {
        this.validateFormFields()
    }

    handleSubmit(e) {
        e.preventDefault();
        this.disableSubmitButton();

        const {
            fnameInput,
            lnameInput,
            emailInput,
            phoneInput,
            jobPositionInput,
            file
        } = this.state;

        var data = {
            firstName: fnameInput,
            lastName: lnameInput,
            email: emailInput,
            phoneNumber: phoneInput,
            jobPosition: jobPositionInput,
            fileName: file.name,
        };

        let presignedUrl = PRESIGNED_URL + this.state.file.name;
        fetch(
            presignedUrl, { method: 'GET' }
        ).then( 
            response => response.json()
        ).then( responseJson => {
            const url = responseJson.fileUploadURL;

            var body = new FormData();
            body.append('file', this.state.file);
            fetch( 
                url, { method: 'PUT', body: this.state.file }
            ).then(res => {
                this.setState({
                    uploadSuccess: "File upload successful",
                    error: undefined
                });

                fetch(
                    SUBMIT_RESUME_URL,
                    {
                        method: 'POST',
                        body: JSON.stringify(data)
                    }
                ).then((response) => {
                    if(response.status === 200) {
                        alert ("Thank you for applying with us. We will contact you as soon as we have reviewed your resume.");
                    } else {
                        alert ("Oops. Something went wrong, please try submitting your resume again.");
                    }
                    this.enableSubmitButton();
                });
            }).catch(err => {
                this.setState({
                    error: "Error Occured while uploading the file",
                    uploadSuccess: undefined
                });
            });
        }).catch( err => {
            this.setState({
                error: "Error Occured while fetching presigned url",
                uploadSuccess: undefined
            });
        });
    }

    handleInput(textInput, stateField, error) {
        let fieldError = error === 'none' ? false : true;
        this.setState({
            [stateField]: textInput,
            [`${stateField}HasError`]: fieldError,
        }, () => {
            this.validateFormFields();
        });
    }

    handleFileUpload(fileToUpload) {
        this.setState({ 
            file: fileToUpload 
        }, () => {
            this.validateFormFields();
        });
    }

    validateFormFields() {
        const {
            fnameInputHasError,
            lnameInputHasError,
            phoneInputHasError,
            emailInputHasError,
            jobPositionInputHasError,
            file
        } = this.state;

        if(!fnameInputHasError && !lnameInputHasError && !phoneInputHasError && !emailInputHasError && !jobPositionInputHasError && file) {
            this.enableSubmitButton()
        } else {
            this.disableSubmitButton()
        }
    }

    disableSubmitButton() {
        this.setState({ isSubmitDisabled: true })
    }

    enableSubmitButton() {
        this.setState({ isSubmitDisabled: false })
    }

    render() {
        return (
            <form className="careers-form-container" action="#" id="contact-us-form">
                <div className="form-row-container">
                    <FormSectionInputText
                        sectionName="First Name*"
                        placeHolder="Your first name"
                        id="fnameInput"
                        getInput={this.handleInput}
                    />
                    <FormSectionInputText
                        sectionName="Last Name*"
                        placeHolder="Your last name"
                        id="lnameInput"
                        getInput={this.handleInput}
                    />
                </div>
                <div className="form-row-container">
                    <FormSectionInputPhone
                        sectionName="Phone Number*"
                        placeHolder="Your phone number"
                        id="phoneInput"
                        getInput={this.handleInput}
                    />
                </div>
                <div className="form-row-container">
                    <FormSectionInputEmail
                        sectionName="Email*"
                        placeHolder="Your email address"
                        id="emailInput"
                        getInput={this.handleInput}
                    />
                </div>
                <div className="form-row-container">
                    <FormSectionInputText
                        sectionName="Job Position*"
                        placeHolder="Position you are applying for"
                        id="jobPositionInput"
                        getInput={this.handleInput} 
                    />
                </div>
                <div className="form-file-upload-container">
                    <FormSectionFileUpload 
                        getFile={this.handleFileUpload}
                    />
                </div>
                <div className="form-submit-row">
                    <input
                        style={{ marginTop: "15px" }}
                        type="submit"
                        value="Submit Resume"
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                        disabled={this.state.isSubmitDisabled}
                    />
                    <div>* = Required Fields</div>
                </div>
            </form>
        );
    }
}

export default ContactForm;