import React, { Component } from 'react';
import '../css/back-to-top-button.css';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
 
class BackToTopButton extends Component {
    constructor() {
        super()

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'     
        })
    }

    render() {
        return (
            <div className="back-to-top-button-container btn" onClick={() => this.handleOnClick()}>
                <ArrowUpwardIcon />
            </div>
        );
    }

}
 
export default BackToTopButton;