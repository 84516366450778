import React, { Component } from 'react';
import '../css/offer.css';

import FavoriteIcon from '@mui/icons-material/Favorite';
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined';
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded';
import EmojiFoodBeverageRoundedIcon from '@mui/icons-material/EmojiFoodBeverageRounded';
import BrushRoundedIcon from '@mui/icons-material/BrushRounded';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import VillaRoundedIcon from '@mui/icons-material/VillaRounded';
import { Offers } from '../helperJS/enums';

class Offer extends Component {
    constructor(props) {
        super(props)

        this.getOfferIcon = this.getOfferIcon.bind(this);
    }

    getOfferIcon(serviceName) {
        let iconStyle = { color: "#292f51", fontSize: 30  }
        switch(serviceName) {
            case Offers.ExpertCareTeam: return <EmojiPeopleRoundedIcon style={iconStyle}/>;
            case Offers.FloorPlans: return <VillaRoundedIcon style={iconStyle}/>;
            case Offers.OurCommunity: return <DirectionsBikeOutlinedIcon style={iconStyle}/>;
            case Offers.SpaciousElegantCommunity: return <Diversity3RoundedIcon style={iconStyle}/>;
            case Offers.GourmetDining: return <EmojiFoodBeverageRoundedIcon style={iconStyle}/>;
            case Offers.ActivitiesAndEvents: return <BrushRoundedIcon style={iconStyle}/>;
            default: return <FavoriteIcon style={iconStyle}/>;
        }
    }

    render() {
        const {
            item: { header, text }
        } = this.props;

        return (
            <div className="offer-container animated fadeIn">
                <div className="offer-icon">{this.getOfferIcon(header)}</div>
                <div className="offer-info">
                    <div className="offer-header">{header}</div>
                    <div className="offer-text">{text}</div>
                </div>
            </div>
        );
    }
}

export default Offer;