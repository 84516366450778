import PropTypes from "prop-types";
import React, { Component } from 'react';
import '../../css/form-section.css';


class FormSectionInputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            error: 'none',
            isInFocus: false,
        }
        this.validateText = this.validateText.bind(this);
        this.updateOnFocus = this.updateOnFocus.bind(this);
    }

    validateText({ target: { value } }) {
        let errorToSet;
        let isInFocusToSet;
        if (value === '') {
            errorToSet = 'This field cannot be blank';
            isInFocusToSet = false;
        } else {
            errorToSet = 'none';
            isInFocusToSet = false;
        }

        this.setState({ 
            text: value,
            error: errorToSet,
            isInFocus: isInFocusToSet
        }, () => {
            this.props.getInput(this.state.text, this.props.id, this.state.error);
        })
    }

    updateOnFocus() {
        this.setState({ isInFocus: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentValue !== prevProps.currentValue) {
            this.setState({ text: this.props.currentValue });
        }
    }

    render() {
        const {
            sectionName,
            placeHolder,
            id,
            // value
        } = this.props;

        const {
            error,
            isInFocus,
        } = this.state;

        let inputClass = (error !== 'none') ? "section-input error" : "section-input";
        let errorMessageClass = (error !== 'none' && !isInFocus) ? "section-error-message" : "section-error-message-hidden";
        return (
            <div className="form-section-container">
                <label className="section-name" htmlFor={id}>{sectionName}</label>
                <input 
                    className={inputClass} 
                    id={id}
                    minLength="1"
                    maxLength="100"
                    placeholder={placeHolder}
                    onFocus={this.updateOnFocus}
                    type="text"
                    onBlur={this.validateText}
                />
                <div className={errorMessageClass}>{error}</div>
            </div>
        );
    }
}

FormSectionInputText.propTypes = {
  getInput: PropTypes.func,
  id: PropTypes.string,
  placeHolder: PropTypes.string,
  sectionName: PropTypes.string
}

export default FormSectionInputText;