export const assistedLivingFloorPlans = [
    {
        name: "The Arlington",
        description: "Studio",
        numOfBedrooms: "S",
        numOfBathrooms: "1",
        squareFootage: "~359 sq ft.",
        imgSrc: require("../assets/floor-plans/al-studio.jpg"),
        webpSrc: require("../assets/floor-plans/al-studio.webp")
    },
    {
        name: "The Bradford",
        description: "One Bedroom",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "~431 sq ft.",
        imgSrc: require("../assets/floor-plans/al-one-bedroom.jpg"),
        webpSrc: require("../assets/floor-plans/al-one-bedroom.webp")
    },
    {
        name: "The Carlisle",
        description: "One Bedroom - Deluxe Suite A",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "~487 sq ft.",
        imgSrc: require("../assets/floor-plans/al-one-bedroom-A.jpg"),
        webpSrc: require("../assets/floor-plans/al-one-bedroom-A.webp")
    },
    {
        name: "The Durham",
        description: "One Bedroom - Deluxe Suite B",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "~489 sq ft.",
        imgSrc: require("../assets/floor-plans/al-one-bedroom-B.jpg"),
        webpSrc: require("../assets/floor-plans/al-one-bedroom-B.webp")
    },
    {
        name: "The Edinburgh",
        description: "Two Bedroom",
        numOfBedrooms: "2",
        numOfBathrooms: "1",
        squareFootage: "~715 sq ft.",
        imgSrc: require("../assets/floor-plans/al-two-bedroom.jpg"),
        webpSrc: require("../assets/floor-plans/al-two-bedroom.webp")
    }
];

export const memoryCareFloorPlans = [
    {
        name: "The Fairfield",
        description: "One Bedroom - Suite A",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "~431 sq ft.",
        imgSrc: require("../assets/floor-plans/mc-one-bedroom-A.jpg"),
        webpSrc: require("../assets/floor-plans/mc-one-bedroom-A.webp")
    },
    {
        name: "The Gloucester",
        description: "One Bedroom - Suite B",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "~453 sq ft.",
        imgSrc: require("../assets/floor-plans/mc-one-bedroom-B.jpg"),
        webpSrc: require("../assets/floor-plans/mc-one-bedroom-B.webp")
    },
    {
        name: "The Hereford",
        description: "One Bedroom - Suite C",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "~465 sq ft.",
        imgSrc: require("../assets/floor-plans/mc-one-bedroom-C.jpg"),
        webpSrc: require("../assets/floor-plans/mc-one-bedroom-C.webp")
    }
];

export const independentCottageFloorPlans = [
    {
        name: "Single Cottage",
        description: "One Bedroom - Duplex Cottage",
        numOfBedrooms: "1",
        numOfBathrooms: "1",
        squareFootage: "1501 sq ft.",
        imgSrc: require("../assets/floor-plans/coming-soon.jpg"),
        webpSrc: require("../assets/floor-plans/coming-soon.webp")
    },
    {
        name: "Duplex Cottage",
        description: "Two Bedroom - Duplex Cottage",
        numOfBedrooms: "2/unit",
        numOfBathrooms: "2/unit",
        squareFootage: "1203 sq ft./unit",
        imgSrc: require("../assets/floor-plans/cottage-floor-plan.jpg"),
        webpSrc: require("../assets/floor-plans/cottage-floor-plan.webp")
    },
];

Object.freeze(assistedLivingFloorPlans);
Object.freeze(memoryCareFloorPlans);