import React from 'react';
import '../css/picture-and-text.css'

import WebpImage from './WebpImage';
 
const PictureAndText = ({ text, imgSrc, webpSrc, altText, textFirst }) => {

    return (
        <div className={`picture-and-text-container`}>
            <div className="text animated fadeIn">
                {
                    text.split("newline").map((i,key) => {
                        return <p key={key}>{i}</p>;
                    })
                }
            </div>
            <div className="picture animated fadeIn">
                <WebpImage
                    imgSrc={imgSrc}
                    webpSrc={webpSrc}
                    altText={altText}
                />
            </div>
        </div>
    );
}
 
export default PictureAndText;