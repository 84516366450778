import PropTypes from "prop-types";
import React, { Component } from 'react';
import '../../css/form-section.css';

class FormSectionInputPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            error: 'none',
            isInFocus: false,
        }
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
        this.updateOnFocus = this.updateOnFocus.bind(this);
    }

    validatePhoneNumber({ target: { value } }) {
        let errorToSet;
        let isInFocusToSet;
        var phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        if (value === '' || !phoneRegex.test(value)) {
            errorToSet = 'Please provide a valid phone number';
            isInFocusToSet = false;
        } else {
            errorToSet = 'none';
            isInFocusToSet = false;
        }

        this.setState({ 
            phoneNumber: value,
            error: errorToSet,
            isInFocus: isInFocusToSet
        }, () => {
            this.props.getInput(this.state.phoneNumber, this.props.id, this.state.error);
        })
    }

    formatPhoneNumber({ target: { value } }) {
        let currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        let formattedNumber;
        if(cvLength < 4) formattedNumber = currentValue;
        else if(cvLength < 7) formattedNumber = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        else formattedNumber = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;

        this.setState({ phoneNumber: formattedNumber });
    };

    updateOnFocus() {
        this.setState({ isInFocus: true });
    }

    render() {
        const {
            sectionName,
            placeHolder,
            id,
        } = this.props;

        const {
            phoneNumber,
            error,
            isInFocus,
        } = this.state;

        let inputClass = (error !== 'none') ? "section-input error" : "section-input";
        let errorMessageClass = (error !== 'none' && !isInFocus) ? "section-error-message" : "section-error-message-hidden";
        return (
            <div className="form-section-container">
                <label className="section-name" htmlFor={id}>{sectionName}</label>
                <input 
                    id={id}
                    type="tel"
                    maxLength="14"
                    value={phoneNumber}
                    className={inputClass}
                    placeholder={placeHolder}
                    onFocus={this.updateOnFocus}
                    onBlur={this.validatePhoneNumber}
                    onChange={this.formatPhoneNumber}
                />
                <div className={errorMessageClass}>{error}</div>
            </div>
        );
    }
}

FormSectionInputPhone.propTypes = {
  getInput: PropTypes.func,
  id: PropTypes.string,
  placeHolder: PropTypes.string,
  sectionName: PropTypes.string
}

export default FormSectionInputPhone;