export const ServicesConstants = {
    AssistedLiving: "Assisted Living",
    MemoryCare: "Memory Care",
    RespiteCare: "Respite Care",
    IndependentCottages: "Independent Cottages",
}

export const Services = [
    {
        heading: ServicesConstants.AssistedLiving,
        link: "/assisted-living",
        slogan: "We offer assisted living services that provide support with the activities of daily living.  Crestview Assisted Living gives you peace of mind, and residents are able to flourish in their best years.",
        aboutText: "Crestview Senior Living community is designed to provide a safe, secure and enriching environment. We strive to provide as much independence as needed, or as much assistance as needed, 24 hours a day.  We provide a “just like home” experience and treat every resident with compassion, no matter how much assistance they may need. Each resident, and their families, is assessed in depth before move-in.  This gives us a detailed perspective of the resident’s range of care, needs and interests. newline We offer single-story assisted living, studio’s, one-bedroom or two-bedroom apartments.",
        serviceAndAmenityBullets: [
            "Maintenance free living",
            "Gourmet, restaurant style dining",
            "Daily activities – Intellectual, physical, recreational and spiritual",
            "Scheduled shopping, outings, transportation to medical appointments",
            "Housekeeping and linen services",
            "Emergency call system pendants",
            "24-hour professional care",
            "Medication management",
            "House call physicians",
            "Wellness Center – physical, occupational and speech therapies",
            "Bistro and Coffee Bar, Salon, Movie Theater, Fitness Center, Library/Business Center",
        ],
        serviceAndAmenityBulletsLinks: [
            {
                text: "Emergency call system pendants",
                url: "https://crestview-assets.s3.us-east-2.amazonaws.com/HM_Single_Button_Pendant_Specs.pdf",
            }
        ],
    },
    {
        heading: ServicesConstants.MemoryCare,
        link: "/memory-care",
        slogan: "We provide a safe and structured neighborhood to improve residents’ quality of life.  Our team is trained to deal with the unique issues that is required for memory care residents.",
        aboutText: "Crestview Senior Living offers a new memory care neighborhood for all your needs. Our trained team of caregivers provide support and care for residents that need this level of service. For those requiring memory care, any move can be challenging and confusing. Our caregivers pay particular attention to residents, to make their move and stay feel safe and secure. Residents are encouraged to bring personal belongings to make our neighborhood seem that much more familiar. newline Our caregivers work closely with residents, their family; and, if required, other professionals, to provide the exceptional care and support they need.",
        serviceAndAmenityBullets: [
            "Maintenance free living",
            "Gourmet, restaurant style dining",
            "Daily activities – Intellectual, physical, recreational and spiritual",
            "Scheduled shopping, outings, transportation to medical appointments",
            "Housekeeping and linen services",
            "Emergency call system pendants",
            "24-hour professional care",
            "Medication management",
            "House call physicians",
            "Wellness Center – physical, occupational and speech therapies",
            "Bistro and Coffee Bar, Salon, Movie Theater, Fitness Center, Library/Business Center",
            "Alarmed doors",
            "Enclosed outdoor spaces",
        ],
        serviceAndAmenityBulletsLinks: [
            {
                text: "Emergency call system pendants",
                url: "https://crestview-assets.s3.us-east-2.amazonaws.com/HM_Single_Button_Pendant_Specs.pdf",
            }
        ],
    },
    {
        heading: ServicesConstants.RespiteCare,
        link: "/respite-care",
        slogan: "Our respite care is ideal for those that need extra time to recover from a hospital stay, or those that just need to get away from the regular routines that cause increased stress and burnout.",
        aboutText: "Our respite care is perfect for those caring for a loved one, but need an occasional time-off for personal needs or that needed break.  We know caring for a senior can be stressful and demanding.  Crestview Senior Living provides a safe, comfortable atmosphere for your loved one, giving you peace of mind knowing your loved one is receiving personal care.  When a caregiver take care of themselves first, they are better able to take care of others.  Our respite care can be for a few days or a few weeks, giving you and your loved one a break from the regular routines that can create increased stress and burnout.  Your loved one will be able to enjoy a different environment and take advantage of our gourmet meals and social activities. newline Our respite care is also ideal for those that need extra time to recover from a hospital stay, or just to stay to see if our community is right for you for a longer stay.",
        serviceAndAmenityBullets: [
            "Maintenance free living",
            "Gourmet, restaurant style dining",
            "Daily activities – Intellectual, physical, recreational and spiritual",
            "Scheduled shopping, outings, transportation to medical appointments",
            "Housekeeping and linen services",
            "Emergency call system pendants",
            "24-hour professional care",
            "Medication management",
            "House call physicians",
            "Wellness Center – physical, occupational and speech therapies",
            "Bistro and Coffee Bar, Salon, Movie Theater, Fitness Center, Library/Business Center",
        ],
        serviceAndAmenityBulletsLinks: [
            {
                text: "Emergency call system pendants",
                url: "https://crestview-assets.s3.us-east-2.amazonaws.com/HM_Single_Button_Pendant_Specs.pdf",
            }
        ],
    },
    {
        heading: ServicesConstants.IndependentCottages,
        link: "/independent-cottages",
        slogan: "Enjoy carefree independent living in style and comfort.  Enjoy a life without home maintenance, landscaping, to do lists and chores.  Enjoy chef prepared meals, and engaging social activities.",
        aboutText: "Are you ready to free yourself of the maintenance, upkeep, yard work and other expenses associated with home ownership? Then Independent Cottages are for you. Crestview Senior Living offers maintenance-free living without sacrificing your freedom and independence. newline Gourmet style meals are provided, should you decide to have a day off from meal preparation. Cottages are offered to ages 55 and older.",
        serviceAndAmenityBullets: [
            "Washer and Dryer Included",
            "Cable, Water, Electric & Internet Services Included",
            "Emergency Call System Pendants",
            "Lawn Services",
            "Fresh, Gourmet Meals Provided",
            "Weekly Housekeeping Provided",
            "Pet Friendly",
        ],
    },
];

export function getSpecificService(serviceToFind) {
    let result = Services.find(service => service.heading === serviceToFind)
    return result;
}

Object.freeze(Services);
Object.freeze(ServicesConstants);