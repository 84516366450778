import React, { Component } from 'react';
import '../css/form.css';

import FormSectionTextArea from './form/FormSectionTextArea';
import FormSectionInputText from './form/FormSectionInputText';
import FormSectionInputEmail from './form/FormSectionInputEmail';
import FormSectionInputPhone from './form/FormSectionInputPhone';

class ContactForm extends Component {
    constructor() {
        super();
        this.state = {
            isSubmitDisabled: false,
            emailInputHasError: true,
            phoneInputHasError: true,
            fnameInputHasError: true,
            lnameInputHasError: true,
            subjectInputHasError: true,
            fnameInput: '',
            lnameInput: '',
            emailInput: '',
            phoneInput: '',
            subjectInput: '',
            messageInput: '',
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.enableSubmitButton = this.enableSubmitButton.bind(this);
        this.validateFormFields = this.validateFormFields.bind(this);
        this.disableSubmitButton = this.disableSubmitButton.bind(this);
    }

    componentDidMount() {
        this.validateFormFields()
    }

    handleSubmit(e) {
        e.preventDefault();
        this.disableSubmitButton();

        const {
            fnameInput,
            lnameInput,
            emailInput,
            phoneInput,
            subjectInput,
            messageInput
        } = this.state;

        var data = {
            firstName: fnameInput,
            lastName: lnameInput,
            email: emailInput,
            phoneNumber: phoneInput,
            subject: subjectInput,
            message: messageInput
        };

        const currentUrl = window.location.href;
        const isProd = currentUrl.includes('crestviewsl.com/');
        console.log("currentUrl", currentUrl);
        data.isProd = isProd;

        // Calls Medright API to create new prospect
        fetch(
            'https://dpy97nvjnk.execute-api.us-west-2.amazonaws.com/prod/create-medright-prospect',
            {
                method: 'post',
                body: JSON.stringify(data)
            }
        ).then((response) => {
            if (response.status === 200) {
                console.log("Successfully created Medright Prospect");
            } else {
                console.log("Something went wrong calling Create Medright Lambda", response.error);
                alert("Oops. Something went wrong, please try submitting the form again.");
            }
        })

        // Calls internal API to send email to corresponding persons (i.e. support@crestviewsl.com)
        fetch(
            'https://api.crestviewsl.com/prod/submit-form',
            {
                method: 'post',
                body: JSON.stringify(data)
            }
        ).then((response) => {
            if (response.status === 200) {                
                this.setState({
                    fnameInput: '',
                    lnameInput: '',
                    emailInput: '',
                    phoneInput: '',
                    subjectInput: '',
                    messageInput: '',
                }, () => {
                    this.enableSubmitButton();
                });
                
                alert("Thank you for contacting us. We will contact you as soon as we have processed your message.");
            } else {
                console.log("Error while sending email to crestview employees.")
            }

            // this.enableSubmitButton();
        });
    }

    handleInput(textInput, stateField, error) {
        let fieldError = error === 'none' ? false : true;
        this.setState({
            [stateField]: textInput,
            [`${stateField}HasError`]: fieldError,
        }, () => {
            this.validateFormFields();
        });
    }

    validateFormFields() {
        const {
            fnameInputHasError,
            lnameInputHasError,
            phoneInputHasError,
            emailInputHasError,
            subjectInputHasError
        } = this.state;

        if (!fnameInputHasError && !lnameInputHasError && !phoneInputHasError && !emailInputHasError && !subjectInputHasError) {
            this.setState({ isSubmitDisabled: false })
        } else {
            this.setState({ isSubmitDisabled: true })
        }
    }

    disableSubmitButton() {
        this.setState({ isSubmitDisabled: true })
    }

    enableSubmitButton() {
        this.setState({ isSubmitDisabled: false })
    }

    render() {
        return (
            <form className="form-container" action="#" id="contact-us-form">
                <div className="form-row-container">
                    <FormSectionInputText
                        sectionName="First Name*"
                        placeHolder="Your first name"
                        id="fnameInput"
                        currentValue={this.state.fnameInput}
                        getInput={this.handleInput}
                    />
                    <FormSectionInputText
                        sectionName="Last Name*"
                        placeHolder="Your last name"
                        id="lnameInput"
                        currentValue={this.state.lnameInput}
                        getInput={this.handleInput}
                    />
                </div>
                <div className="form-row-container">
                    <FormSectionInputPhone
                        sectionName="Phone Number*"
                        placeHolder="Your phone number"
                        id="phoneInput"
                        value={this.state.phoneInput}
                        getInput={this.handleInput}
                    />
                </div>
                <div className="form-row-container">
                    <FormSectionInputEmail
                        sectionName="Email*"
                        placeHolder="Your email address"
                        id="emailInput"
                        value={this.state.emailInput}
                        getInput={this.handleInput}
                    />
                </div>
                <div className="form-row-container">
                    <FormSectionInputText
                        sectionName="Subject*"
                        placeHolder="Subject of the message"
                        id="subjectInput"
                        value={this.state.subjectInput}
                        getInput={this.handleInput} />
                </div>
                <div className="form-row-container">
                    <FormSectionTextArea
                        sectionName="Message*"
                        placeHolder="Write us something"
                        id="messageInput"
                        value={this.state.messageInput}
                        getInputToPass={this.handleInput}
                    />
                </div>
                <div className="form-submit-row">
                    <input
                        style={{ marginTop: "15px" }}
                        type="submit"
                        value="Send Message"
                        className="btn btn-primary"
                        onClick={this.handleSubmit}
                        disabled={this.state.isSubmitDisabled}
                    />
                    <div>* = Required Fields</div>
                </div>
            </form>
        );
    }
}

export default ContactForm;