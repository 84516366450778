export const ItemComponents = {
    Service: "serviceComponent",
    FloorPlans: "floorPlanComponent",
    Offers: "offers",
    Gallery: "gallery",
};

export const Offers = {
    ExpertCareTeam: "EXPERT CARE TEAM",
    FloorPlans: "STYLISH, ATTRACTIVE FLOOR PLANS",
    OurCommunity: "OUR COMMUNITY",
    SpaciousElegantCommunity: "SPACIOUS, ELEGANT COMMUNITY",
    GourmetDining: "GOURMET DINING",
    ActivitiesAndEvents: "ACTIVITIES & EVENTS",
};

export const Pages = {
    Home: "Home",
    AssistedLiving: "AssistedLiving",
    MemoryCare: "MemoryCare",
    RespiteCare: "RespiteCare",
    IndependentCottages: "IndependentCottages",
    Gallery: "Gallery",
    FloorPlans: "FloorPlans",
    ContactUs: "ContactUs",
    Careers: "Careers",
    Covid19: "Covid19",
    Dining: "Dining",
};

Object.freeze(ItemComponents);
Object.freeze(Offers);
Object.freeze(Pages);