import React, { Component } from 'react';
import { Pages } from '../helperJS/enums';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Footer from './Footer';
import Home from '../pages/Home';
import Error from '../pages/Error';
import Dining from '../pages/Dining';
import Gallery from '../pages/Gallery';
import Careers from '../pages/Careers';
import Contact from '../pages/Contact';
import PageButtons from './PageButtons';
import MobileDrawer from './MobileDrawer';
import StickyHeader from './StickyHeader';
import FloorPlans from '../pages/FloorPlans';
import MemoryCare from '../pages/MemoryCare';
import RespiteCare from '../pages/RespiteCare';
import Covid19Page from '../pages/Covid19Page';
import AssistedLiving from '../pages/AssistedLiving';
import ReactHelmetWrapper from './ReactHelmetWrapper';
// import FoundersClubModal from './modals/FoundersClubModal';
import ScrollToTopOnPageLoad from './ScrollToTopOnPageLoad';
import IndependentCottages from '../pages/IndependentCottages';

class App extends Component {
	constructor() {
		super();
		this.state = {
			isMobile: window.innerWidth <= 576,
			// isFoundersClubModalOpen: false,
			isCovidAlertOpen: true,
		}

		this.resize = this.resize.bind(this);
		this.getPage = this.getPage.bind(this);
		// this.openFoundersClubModal = this.openFoundersClubModal.bind(this)
		// this.closeFoundersClubModal = this.closeFoundersClubModal.bind(this)
		this.closeCovidAlert = this.closeCovidAlert.bind(this);
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
		// setTimeout(() => {
        //     this.setState({ firstVisit: true });
        // }, 1000);
		this.setState({ 
			viewPopup: false, 
		})
	}

	resize() {
		if(window.innerWidth <= 576) {
			this.setState({isMobile: true});
		} else {
			this.setState({isMobile: false});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.state.firstVisit !== nextState.firstVisit
			// || this.state.isFoundersClubModalOpen !== nextState.isFoundersClubModalOpen
			|| this.state.isCovidAlertOpen !== nextState.isCovidAlertOpen;
	}

	getPage() {
		let curPath = window.location.pathname;
		curPath.toLowerCase();
		switch(curPath) {
			case "/": return Pages.Home
			case "/assisted-living": return Pages.AssistedLiving
			case "/memory-care": return Pages.MemoryCare
			case "/respite-care": return Pages.RespiteCare
			case "/independent-cottages": return Pages.IndependentCottages
			case "/floor-plans": return Pages.FloorPlans
			case "/contact": return Pages.ContactUs
			case "/careers": return Pages.Careers
			case "/covid-19": return Pages.Covid19
			case "/dining": return Pages.Dining
			default: return Pages.Home
		}
	}

	// openFoundersClubModal = () => {
	// 	this.setState({ isFoundersClubModalOpen: true })
	// }

	// closeFoundersClubModal = () => {
	// 	this.setState({ isFoundersClubModalOpen: false })
	// }

	closeCovidAlert = () => {
		this.setState({ isCovidAlertOpen: false })
	}

	render() {
		const {
			// isFoundersClubModalOpen,
			isCovidAlertOpen
		} = this.state;

		return (
			<BrowserRouter>
				{/* <FoundersClubModal 
					isOpen={isFoundersClubModalOpen}
					closeModal={this.closeFoundersClubModal}
				/> */}
				{/* { this.state.isMobile 
					? <MobileDrawer 
						isCovidAlertOpen={isCovidAlertOpen} 
						closeCovidAlertBanner={this.closeCovidAlert}
					/> 
					: <StickyHeader 
						isCovidAlertOpen={isCovidAlertOpen}
						closeCovidAlertBanner={this.closeCovidAlert}
					/>
				} */}

				<StickyHeader 
					isCovidAlertOpen={isCovidAlertOpen}
					closeCovidAlertBanner={this.closeCovidAlert}
				/>

				<ReactHelmetWrapper page={this.getPage} />
				<Switch>
					<Route path="/" render={() => <Home />} exact/>
					<Route path="/assisted-living" component={AssistedLiving}/>
					<Route path="/memory-care" component={MemoryCare}/>
					<Route path="/respite-care" component={RespiteCare}/>
					<Route path="/independent-cottages" component={IndependentCottages}/>
					<Route path="/gallery" component={Gallery}/>
					<Route path="/floor-plans" render={FloorPlans}/>
					<Route path="/contact" render={() => <Contact />} />
					<Route path="/careers" component={() => <Careers />}/>
					<Route path="/covid-19" component={Covid19Page}/>
					<Route path="/dining" component={Dining}/>
					<Route component={Error}/>
				</Switch>
				<ScrollToTopOnPageLoad />
				{ !this.state.isMobile 
					&& <PageButtons 
						// openModal={this.openFoundersClubModal} 
						openChristmasModal={this.openChristmasModal}
					/>
				}
				<Footer />
			</BrowserRouter>
			
		);
	}
}
 
export default App;