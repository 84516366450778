import React, { Component } from 'react';
import '../css/service.css';

import OutlinedButton from './OutlinedButton';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import { ServicesConstants } from '../helperJS/services';

class ServiceItem extends Component {
    constructor(props) {
        super(props)

        this.getServiceIcon = this.getServiceIcon.bind(this);
    }

    getServiceIcon(serviceName) {
        let iconStyle = { color: "#292f51", fontSize: 80  }
        switch(serviceName) {
            case ServicesConstants.AssistedLiving: return <RemoveRedEyeIcon style={iconStyle}/>;
            case ServicesConstants.MemoryCare: return <LoyaltyOutlinedIcon style={iconStyle}/>;
            case ServicesConstants.RespiteCare: return <WorkRoundedIcon style={iconStyle}/>;
            case ServicesConstants.IndependentCottages: return <HouseRoundedIcon style={iconStyle}/>;
            default: return <HouseRoundedIcon style={iconStyle}/>;
        }
    }

    render() {
        const {
            item: { heading, slogan, link },
            // index,
        } = this.props;

        return (
            <div className="service-container animated fadeIn">
                <div className="service-item-icon">{this.getServiceIcon(heading)}</div>
                <div className="service-item-name">{heading}</div>
                <div className="service-item-text">{slogan}</div>
                <OutlinedButton text={`Learn More - ${heading}`} linkTo={link} style={{ width: "100%"}} />
            </div>
        );
    }
}

export default ServiceItem;