import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@material-ui/core/Divider';
import '../css/job-expansion-panel.css'

const JobExpansionPanel = ({ job: { jobTitle, jobDescription, qualifications, jobType }, scrollToForm }) => {

	return (
		<div className="job-panel-container">
			<ExpansionPanel style={{'width': '85%'}}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1c-content"
					id="panel1c-header"
					style={{'backgroundColor': 'lightgrey', 'color': '#292f51'}}
				>
					<div className="summary-section">
						<div className="column">
							<Typography variant="h5">{jobTitle}</Typography>
						</div>
						<div className="column">
							<Typography className="secondary-heading" variant="h6" aligncenter="true">{jobType}</Typography>
						</div>
					</div>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className="details-section">
					<div className="detail-column">{jobDescription}</div>
					<div className="detail-column">
						{ qualifications.length > 0 &&
							<div>
								Qualifications: <br/>
								<ul type="disc">
									{
										qualifications.map ( qualification =>
											<li key={qualification}>{qualification}</li>
										)
									}
								</ul>
							</div>
						}
					</div>
				</ExpansionPanelDetails>
				<Divider />
				<ExpansionPanelActions>
					<div className="apply-now-button">
						<div className="outlined-button">
							<div onClick={scrollToForm} className="btn btn-primary btn-outline">Apply Now</div>
						</div>
					</div>
				</ExpansionPanelActions>
			</ExpansionPanel>
		</div>
	);
}

export default JobExpansionPanel;