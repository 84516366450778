import React from 'react';

import Divider from '../components/Divider';
import SmallBanner from '../components/SmallBanner';
import SectionHeader from '../components/SectionHeader';
import ServiceSectionHeader from '../components/ServiceSectionHeader';
import PictureAndText from '../components/PictureAndText';
import PictureAndBullets from '../components/PictureAndBullets';
import { getSpecificService, ServicesConstants } from '../helperJS/services';

const AssistedLiving = () => {
    let assistedLiving = getSpecificService(ServicesConstants.AssistedLiving); 

    return (
        <React.Fragment>
            <SmallBanner 
                imgSrc={require("../assets/shared/services-banner.jpg")}
                webpSrc={require("../assets/shared/services-banner.webp")}
                altText="Crestview Senior Living Assisted Living"
            />
            <ServiceSectionHeader text="Assisted Living" />
            <PictureAndText 
                text={assistedLiving.aboutText}
                imgSrc={require("../assets/assisted-living/about-text-pic.jpg")}
                webpSrc={require("../assets/assisted-living/about-text-pic.webp")}
                altText="Crestview Senior Living Assisted Living"
                textFirst={true}
            />
            <Divider />
            <SectionHeader text="Service & Amenities" />
            <PictureAndBullets
                bulletsList={assistedLiving.serviceAndAmenityBullets}
                bulletsListLinks={assistedLiving.serviceAndAmenityBulletsLinks}
                imgSrc={require("../assets/assisted-living/service-and-amenities.jpg")}
                webpSrc={require("../assets/assisted-living/service-and-amenities.webp")}
                altText="Crestview Senior Living Assisted Living Services and Amenities"
                bulletsFirst={false}
            />
        </React.Fragment>
    );
}
 
export default AssistedLiving;