export const galleryImages = [
    {
        description: "Birds Eye View - 1",
        webpImage: require('../assets/gallery/aerial-4.webp'),
        jpgImage: require('../assets/gallery/aerial-4.jpg')
    },
    {
        description: "Birds Eye View - 2",
        webpImage: require('../assets/gallery/aerial-5.webp'),
        jpgImage: require('../assets/gallery/aerial-5.jpg')
    },
    {
        description: "Birds Eye View - Assisted Living Patio",
        webpImage: require('../assets/gallery/aerial-7.webp'),
        jpgImage: require('../assets/gallery/aerial-7.jpg')
    },
    {
        description: "Birds Eye View - Memory Care Patio",
        webpImage: require('../assets/gallery/aerial-8.webp'),
        jpgImage: require('../assets/gallery/aerial-8.jpg')
    },
    {
        description: "Entrance Fountain",
        webpImage: require('../assets/gallery/fountain.webp'),
        jpgImage: require('../assets/gallery/fountain.jpg')
    },
    {
        description: "Entrance Canopy",
        webpImage: require('../assets/gallery/canopy.webp'),
        jpgImage: require('../assets/gallery/canopy.jpg')
    },
    {
        description: "Patio Pergola",
        webpImage: require('../assets/gallery/patio-pergola.webp'),
        jpgImage: require('../assets/gallery/patio-pergola.jpg')
    },
    {
        description: "Garden Pergola",
        webpImage: require('../assets/gallery/garden-pergola.webp'),
        jpgImage: require('../assets/gallery/garden-pergola.jpg')
    },
    {
        description: "Putting Green",
        webpImage: require('../assets/gallery/outdoor-putting-green.webp'),
        jpgImage: require('../assets/gallery/outdoor-putting-green.jpg')
    },
    {
        description: "Vegetable Garden",
        webpImage: require('../assets/gallery/vegetable-garden.webp'),
        jpgImage: require('../assets/gallery/vegetable-garden.jpg')
    },
    {
        description: "Outdoor Patio Area",
        webpImage: require('../assets/gallery/outdoor-patio-area.webp'),
        jpgImage: require('../assets/gallery/outdoor-patio-area.jpg')
    },
    {
        description: "Outdoor Garden",
        webpImage: require('../assets/gallery/outdoor-garden.webp'),
        jpgImage: require('../assets/gallery/outdoor-garden.jpg')
    },



    {
        description: "Apartment Bedroom - 1",
        webpImage: require('../assets/gallery/apartment-bedroom.webp'),
        jpgImage: require('../assets/gallery/apartment-bedroom.jpg')
    },
    {
        description: "Apartment Bedroom - 2",
        webpImage: require('../assets/gallery/apartment-bedroom-2.webp'),
        jpgImage: require('../assets/gallery/apartment-bedroom-2.jpg')
    },
    {
        description: "Apartment Bathroom",
        webpImage: require('../assets/gallery/apartment-bathroom.webp'),
        jpgImage: require('../assets/gallery/apartment-bathroom.jpg')
    },
    {
        description: "Apartment Living Room - 1",
        webpImage: require('../assets/gallery/apartment-living-room.webp'),
        jpgImage: require('../assets/gallery/apartment-living-room.jpg')
    },
    {
        description: "Apartment Living Room - 2",
        webpImage: require('../assets/gallery/apartment-living-room-2.webp'),
        jpgImage: require('../assets/gallery/apartment-living-room-2.jpg')
    },
    {
        description: "Apartment Kitchen",
        webpImage: require('../assets/gallery/apartment-kitchen.webp'),
        jpgImage: require('../assets/gallery/apartment-kitchen.jpg')
    },



    
    {
        description: "Lounge View - 1",
        webpImage: require('../assets/gallery/lounge-1.webp'),
        jpgImage: require('../assets/gallery/lounge-1.jpg')
    },
    {
        description: "Lounge View - 2",
        webpImage: require('../assets/gallery/lounge-2.webp'),
        jpgImage: require('../assets/gallery/lounge-2.jpg')
    },
    {
        description: "Mini Lounge Area",
        webpImage: require('../assets/gallery/lounge-area.webp'),
        jpgImage: require('../assets/gallery/lounge-area.jpg')
    },
    {
        description: "Dining Room",
        webpImage: require('../assets/gallery/dining-room.webp'),
        jpgImage: require('../assets/gallery/dining-room.jpg')
    },
];


export const kitchenImages = [
    {
        description: "Chicken and Mashed Potatoes",
        webpImage: require('../assets/gallery/kitchen/chicken-and-mashed-potatoes.webp'),
        jpgImage: require('../assets/gallery/kitchen/chicken-and-mashed-potatoes.jpg')
    },
    {
        description: "Chicken and Mashed Potatoes - Up Close",
        webpImage: require('../assets/gallery/kitchen/chicken-and-mashed-potatoes-up-close.webp'),
        jpgImage: require('../assets/gallery/kitchen/chicken-and-mashed-potatoes-up-close.jpg')
    },
    {
        description: "Potato and Veggies",
        webpImage: require('../assets/gallery/kitchen/potato-and-veggies.webp'),
        jpgImage: require('../assets/gallery/kitchen/potato-and-veggies.jpg')
    },
    {
        description: "Potato and Veggies - Up Close",
        webpImage: require('../assets/gallery/kitchen/potato-and-veggies-up-close.webp'),
        jpgImage: require('../assets/gallery/kitchen/potato-and-veggies-up-close.jpg')
    },
    {
        description: "Strawberry Cheesecake",
        webpImage: require('../assets/gallery/kitchen/strawberry-cheesecake.webp'),
        jpgImage: require('../assets/gallery/kitchen/strawberry-cheesecake.jpg')
    },
    {
        description: "Lemon Meringue",
        webpImage: require('../assets/gallery/kitchen/lemon-meringue.webp'),
        jpgImage: require('../assets/gallery/kitchen/lemon-meringue.jpg')
    },
];

Object.freeze(galleryImages);
Object.freeze(kitchenImages);