import React from 'react';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import '../css/covid-expansion-panel.css'

const CovidExpansionPanel = ({ covidText: { header, text, subheader } }) => {
	return (
		<div className="job-panel-container">
            <ExpansionPanel style={{'width': '85%'}}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon style={{ 'color': '#ab926e' }}/>}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    style={{'backgroundColor': '#292F51', 'color': '#ab926e'}}
                >
                    <div className="summary-section">
                        <div className="column">
                            <Typography variant="h5">{header}</Typography>
                        </div>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="details-section">
                    <div className="details-section-text">
                        { subheader &&
                            <React.Fragment>
                                <div>{subheader}</div>
                                <p></p>
                            </React.Fragment>
                        }
                        <div>{text}</div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
		</div>
	);
}

export default CovidExpansionPanel;